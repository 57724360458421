import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "../style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole } from "../../../utils/cookie";
import { MultiSelect } from "react-multi-select-component";


const SubDistributorPointModel = () => {

  const toast = useToast();
  const [userRole, setUserRole] = React.useState(getRole())
  const [distributorList, setDistributorList] = useState([]);
  const [subDistributorList, setSubDistributorList] = useState([]);

  const [myPoints, setMyPoints] = useState(0);


  const getMyPoints = async () => {
    try {
      const response = await apiAdminConfig.get("auth/me");
      if (response?.data) {
        setMyPoints(response?.data?.points || 0);
      }
    } catch (error) {
      console.log("gameListError", error);
    }
  };


  const getDistributor = async () => {
    try {
      const response = await apiAdminConfig.get(`users/list?userBy=distributor`);

      if (response?.data?.length > 0) {
        const subDistributorList = response?.data?.map((item) => ({
          label: item.userName,
          value: item.id,
        }));
        setDistributorList(subDistributorList);
      }
    } catch (error) {
      console.log("gameListError", error);
    }
  };

  useEffect(() => {
    getMyPoints();
    if (userRole === "admin" || userRole === "partner") {
      getDistributor();
    }


    if (userRole === "distributor") {
      getSubDistributor()
    }

  }, []);

  const getSubDistributor = async (userId = '') => {
    try {
      const response = await apiAdminConfig.get(`users/list?userBy=subDistributor&userId=${userId}`);

      if (response?.data?.length > 0) {
        const distributorList = response?.data?.map((item) => ({
          label: item.userName,
          value: item.id,
        }));
        setSubDistributorList(distributorList);
      }
    } catch (error) {
      console.log("gameListError", error);
    }
  };


  const [initialValues, setInitialValues] = useState({
    receiver: '',
    points: '',
    verifyPassword: '',
    type: '',
    distributor: '',
  });

  const handleAddPoints = () => {
    setFieldValue("type", "credit");
    handleSubmit();
  };

  const handleSubPoints = () => {
    setFieldValue("type", "debit");
    handleSubmit();
  };


  const validationSchema = yup.object().shape({
    receiver: yup.string().required("Receiver is a required field"),
    points: yup.string().required("Points is a required field"),
    verifyPassword: yup.string().required("Transaction Password is a required field"),
  });

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, resetForm } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        const response = await apiAdminConfig.post("points/add-points", values);
        if (response?.status === 201) {
          toast({
            message: 'Points Added Successfully',
            bg: 'success',
          });

          getMyPoints();
          resetForm();
        } else {
          toast({
            message: response?.data?.message,
            bg: 'danger',
          });
        }
      } catch (error) {
        const { response } = error;
        toast({
          message: response?.data?.message,
          bg: "danger",
        });
      }
    },
  });

  return (
    <main id="events">
      <Container className="admin_padding">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // margin: "14px 0",
            alignItems: "center",
          }}
          className="table-hader-container"
        >
          <div>
            <h3 className="table-hader-name">ADD POINTS</h3>
          </div>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">ENTER DETAILS</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate>
                <Row >
                  <Col className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Admin Total Points</Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        value={myPoints}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Transaction Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="verifyPassword"
                        placeholder="Enter Transaction Password"
                        value={values.verifyPassword}
                        onChange={handleChange}
                        isInvalid={touched.verifyPassword && errors.verifyPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.verifyPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  {
                    (userRole === "admin" || userRole === "partner") && (
                      <Col md={6} className="mb-3">
                        <Form.Group controlId="validationFormik01">
                          <Form.Label>Distributor</Form.Label>
                          <Form.Select
                            as="select"
                            name="distributor"
                            value={values.distributor}
                            onChange={(e) => {
                              setFieldValue("distributor", e.target.value)
                              if (e.target.value) {
                                getSubDistributor(e.target.value)
                              } else {
                                setSubDistributorList([])
                              }
                            }}
                          >
                            <option value="">Select Distributor</option>
                            {distributorList.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )
                  }

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Sub Distributor</Form.Label>
                      <Form.Select
                        as="select"
                        name="receiver"
                        value={values.receiver}
                        disabled={(userRole === "admin" || userRole === "partner") && !values.distributor}
                        onChange={(e) => setFieldValue("receiver", e.target.value)}
                        isInvalid={touched.receiver && errors.receiver}
                      >
                        <option value="">Select Sub Distributor</option>
                        {subDistributorList.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.receiver}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Add Points</Form.Label>
                      <Form.Control
                        type="number"
                        name="points"
                        placeholder="Enter Points"
                        value={values.points}
                        onChange={handleChange}
                        isInvalid={touched.points && errors.points}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.points}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <div
                  className="footer-btns"
                  style={{ display: "flex", gap: "10px", marginTop: "20px" }}
                >
                  <Button
                    type="button"
                    className="input_file_button bg-primary"
                    onClick={handleAddPoints}
                  >
                    ADD POINTS
                  </Button>
                  <Button
                    type="button"
                    className="input_file_button bg-danger"
                    onClick={handleSubPoints}
                  >
                    SUB POINTS
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default SubDistributorPointModel;
