import React, { useState, useEffect } from "react";
import { Button, Container, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import useToast from "../../../provider/Toast/useToast";
import { apiAdminConfig } from "../../../utils/api";
import { getRole } from "../../../utils/cookie";

const PartnerPointModel = () => {
  const toast = useToast();
  const [userRole, setUserRole] = useState(getRole());
  const [partnerList, setPartnerList] = useState([]);
  const [myPoints, setMyPoints] = useState(0);

  const getPartnerList = async () => {
    try {
      const response = await apiAdminConfig.get("users/get/partner");
      if (response?.data?.results?.length > 0) {
        const partnerList = response?.data?.results.map((item) => ({
          label: item.userName,
          value: item.id,
        }));
        setPartnerList(partnerList);
      }
    } catch (error) {
      console.log("gameListError", error);
    }
  };

  const getMyPoints = async () => {
    try {
      const response = await apiAdminConfig.get("auth/me");
      if (response?.data) {
        setMyPoints(response?.data?.points || 0);
      }
    } catch (error) {
      console.log("gameListError", error);
    }
  };

  useEffect(() => {
    getPartnerList();
    getMyPoints();
  }, []);

  const [initialValues, setInitialValues] = useState({
    receiver: '',
    points: '',
    verifyPassword: '',
    type: '',
  });

  const validationSchema = yup.object().shape({
    receiver: yup.string().required("Receiver is a required field"),
    points: yup.string().required("Points is a required field"),
    verifyPassword: yup.string().required("Transaction Password is a required field"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      try {
        const response = await apiAdminConfig.post("points/add-points", formik.values);
        if (response?.status === 201) {
          toast({
            message: 'Points Added Successfully',
            bg: 'success',
          });

          getMyPoints();
          formik.resetForm();
        } else {
          toast({
            message: response?.data?.message,
            bg: 'danger',
          });
        }
      } catch (error) {
        const { response } = error;
        toast({
          message: response?.data?.message,
          bg: "danger",
        });
      }
    },
  });

  const handleAddPoints = () => {
    formik.setFieldValue("type", "credit");
    formik.handleSubmit();
  };

  const handleSubPoints = () => {
    formik.setFieldValue("type", "debit");
    formik.handleSubmit();
  };

  return (
    <main id="events">
      <Container className="admin_padding">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="table-hader-container"
        >
          <div>
            <h3 className="table-hader-name">ADD POINTS</h3>
          </div>
        </div>
        <div className="admin_form">
          <div className="detail-heading">
            <h6 className="text-white">ENTER DETAILS</h6>
          </div>
          <Row className="admin_add_form">
            <Col>
              <Form noValidate>
                <Row>
                  <Col className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Admin Total Points</Form.Label>
                      <Form.Control
                        type="text"
                        name="userName"
                        value={myPoints}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Transaction Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="verifyPassword"
                        placeholder="Enter Transaction Password"
                        value={formik.values.verifyPassword}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.verifyPassword && formik.errors.verifyPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.verifyPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Partner</Form.Label>
                      <Form.Select
                        as="select"
                        name="receiver"
                        value={formik.values.receiver}
                        onChange={(e) => formik.setFieldValue("receiver", e.target.value)}
                        isInvalid={formik.touched.receiver && formik.errors.receiver}
                      >
                        <option value="">Select Partner</option>
                        {partnerList.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.receiver}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={6} className="mb-3">
                    <Form.Group controlId="validationFormik01">
                      <Form.Label>Add Points</Form.Label>
                      <Form.Control
                        type="number"
                        name="points"
                        placeholder="Enter Points"
                        value={formik.values.points}
                        onChange={formik.handleChange}
                        isInvalid={formik.touched.points && formik.errors.points}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.points}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <div
                  className="footer-btns"
                  style={{ display: "flex", gap: "10px" }}
                >
                  <Button
                    type="button"
                    className="input_file_button bg-primary"
                    onClick={handleAddPoints}
                  >
                    ADD POINTS
                  </Button>
                  <Button
                    type="button"
                    className="input_file_button bg-danger"
                    onClick={handleSubPoints}
                  >
                    SUB POINTS
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </main>
  );
};

export default PartnerPointModel;
