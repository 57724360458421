import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { Outlet, Link, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  UserAddOutlined,
  TeamOutlined,
  DollarCircleOutlined,
  ShareAltOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
  DownOutlined,
  CaretDownOutlined

} from "@ant-design/icons";
import { Menu, Layout, Avatar, Button, Popover, Select } from "antd";
import { useAuth } from "../Component/hook/auth";
import { CloseOutlined } from '@ant-design/icons';

const { Header, Sider, Content } = Layout;

function Sidebar() {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 767);
  const [isWindowShow, setIsWindowShow] = useState(window.innerWidth >= 767);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const { logout } = useAuth();

  const navigateTo = useNavigate();

  const data = localStorage.getItem('userDetail');
  const userData = JSON.parse(data);
  const userRole = userData?.role;
  const userName = userData?.userName?.charAt(0)?.toUpperCase() + userData?.userName?.slice(1).toLowerCase();

  useEffect(() => {
    const handleResize = () => {
      setIsWindowShow(window.innerWidth >= 767)
      setCollapsed(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleSubMenuClick = (key) => {
    setOpenSubMenu(openSubMenu === key ? null : key); // Toggle the submenu open/close
  };

  const content = (
    <div>
      <Button className="admin-topbar-btn" onClick={() => navigateTo('/reset-password')}>
        {/* <UserOutlined /> */}
        Reset Password
      </Button>

      <Button className="admin-topbar-btn" onClick={logout}>
        {/* <LogoutOutlined /> */}
        LogOut
      </Button>
    </div>
  );

  const iconStyle = {
    color: '#ffffff',
    backgroundColor: '#0033cc',
    borderRadius: '50%',
    padding: '8px'
  };



  return (
    <>

      {
        collapsed ?


          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              width={0}
              style={{
                position: "fixed",
                height: "100vh",
                zIndex: "999",
                left: "0",
              }}
            >
              <button
                title="Toggle Menu"
                className="sidebarWrapperButton"
                onClick={toggleCollapsed}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>

              <div className="sidebarLogo">
                {isWindowShow ? <h4>GOA ADMIN</h4> : <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>{userName}</h4>}
              </div>

              <Menu mode="inline" selectedKeys={[openSubMenu]}>
                <Menu.Item key="Dashboard" icon={<HomeOutlined style={iconStyle} onClick={() => setOpenSubMenu(null)} />}>
                  <span>DASHBOARD</span>
                  <Link to="/" />
                </Menu.Item>

                <Menu.SubMenu title="CREATE MEMBER" key="crate-member" icon={<MenuFoldOutlined style={iconStyle} onTitleClick={() => handleSubMenuClick('create-member')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Create-Register" >
                      <span>ADMIN</span>
                      <Link to="/admin/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Partner" >
                      <span>PARTNER</span>
                      <Link to="/partner/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Distributor" >
                      <span>DISTRIBUTOR</span>
                      <Link to="/distributor/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Sub Distributor" >
                      <span>SUB DISTRIBUTOR</span>
                      <Link to="/sub-distributor/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Agent" >
                      <span>AGENT</span>
                      <Link to="/agent/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-User" >
                      <span>USER</span>
                      <Link to="/user/form" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                <Menu.SubMenu title="MEMBER LIST" key="menu" icon={<MenuFoldOutlined style={iconStyle} onTitleClick={() => handleSubMenuClick('menu')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Register" >
                      <span>ADMIN</span>
                      <Link to="/admin" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Partner" >
                      <span>PARTNER</span>
                      <Link to="/partner" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Distributor" >
                      <span>DISTRIBUTOR</span>
                      <Link to="/distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Sub Distributor" >
                      <span>SUB DISTRIBUTOR</span>
                      <Link to="/sub-distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Agent" >
                      <span>AGENT</span>
                      <Link to="/agent" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="User" >
                      <span>USER</span>
                      <Link to="/user" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINT MANAGEMENT" key="pointMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="PartnerPoint" >
                        <span>PARTNER</span>
                        <Link to="/partner/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="DistributorPoint" >
                        <span>DISTRIBUTOR</span>
                        <Link to="/distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Sub Distributor Point" >
                        <span>SUB DISTRIBUTOR</span>
                        <Link to="/sub-distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="AgentPoint" >
                        <span>AGENT</span>
                        <Link to="/agent/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="UserPoint" >
                        <span>USER</span>
                        <Link to="/user/point" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}

                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINT MANAGEMENT" key="pointMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="PartnerPoint" >
                        <span>PARTNER</span>
                        <Link to="/partner/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="DistributorPoint" >
                        <span>DISTRIBUTOR</span>
                        <Link to="/distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Sub Distributor Point" >
                        <span>SUB DISTRIBUTOR</span>
                        <Link to="/sub-distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="AgentPoint" >
                        <span>AGENT</span>
                        <Link to="/agent/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="UserPoint" >
                        <span>USER</span>
                        <Link to="/user/point" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}

                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Game" icon={<HomeOutlined style={iconStyle} />}>
                      <span>GAME</span>
                      <Link to="/game" />
                    </Menu.Item>
                    <Menu.Item key="Commission" icon={<HomeOutlined style={iconStyle} />}>
                      <span>COMMISSION</span>
                      <Link to="/commission" />
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>

            <Layout style={{ marginLeft: 0 }}>
              <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                <div className="topbar">
                  <div className="topbarWrapper d-flex justify-content-end">
                    <div className="topRight">
                      {/* <div className="topbar-text">
                        <h6>{userData?.userName?.toUpperCase()}</h6>
                      </div>
                      <div className="topbarIcons">
                        <Popover content={content} trigger="hover">
                          <CaretDownOutlined />

                          <Avatar
                            className="admin-avatar"
                            title={userData?.name?.toUpperCase()}
                            icon={<UserOutlined />}
                          />
                        </Popover>
                      </div> */}

                      <Popover content={content} trigger={['hover', 'click']} placement="bottom">
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <div className="topbar-text">
                            <h6>{userData?.userName?.toUpperCase()}</h6>
                          </div>
                          <div className="topbarIcons" style={{ marginLeft: 8 }}>
                            <CaretDownOutlined />
                          </div>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              </Header>

              <Content style={{ marginTop: 60 }}>
                <Outlet />
              </Content>
            </Layout>
          </Layout>
          :
          <Layout>
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              width={240}
              style={{
                position: "fixed",
                height: "100vh",
                zIndex: "999",
                left: "240",
              }}
            >
              {
                isWindowShow &&
                <button
                  title="Toggle Menu"
                  className="sidebarWrapperButton"
                  onClick={toggleCollapsed}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              }

              <div className="sidebarLogo d-flex justify-content-between align-items-center">

                {/* <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>GOA ADMIN</h4> */}
                {isWindowShow ? <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>GOA ADMIN</h4> : <h4 style={{ color: "rgb(0, 0, 0)", paddingLeft: "22px" }}>{userName}</h4>}
                {
                  !isWindowShow
                  &&
                  <button
                    title="Toggle Menu"
                    onClick={toggleCollapsed}
                    //   style={{
                    //     display: "flex",
                    //     alignItems: "center",
                    //     background: "none",
                    //     border: "none",
                    //     padding: 0,
                    //     cursor: "pointer",
                    //      width:'20px',
                    //     //  height:'20px'
                    // }}
                    // className="d-flex align-center"
                    className="close-button"
                  >

                    {/* <CloseOutlined style={{ color: "rgb(0, 0, 0)" ,paddingRight:"10px" }} className="close-icon"/> */}
                    <CloseOutlined style={{ color: "rgb(0, 0, 0)", paddingRight: "10px", fontSize: "20px" }} />

                    {/* <span></span> */}
                    {/* <span></span> */}
                  </button>
                }
              </div>

              <Menu mode="inline"
                // defaultOpenKeys={['menu']}
                selectedKeys={[openSubMenu]}
              >
                <Menu.Item key="Dashboard" icon={<HomeOutlined style={iconStyle} onClick={() => setOpenSubMenu(null)} />}>
                  <span>DASHBOARD</span>
                  <Link to="/" />
                </Menu.Item>

                <Menu.SubMenu title="CREATE MEMBER" key="crate-member" icon={<MenuFoldOutlined style={iconStyle} onTitleClick={() => handleSubMenuClick('create-member')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Create-Register" >
                      <span>ADMIN</span>
                      <Link to="/admin/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Partner" >
                      <span>PARTNER</span>
                      <Link to="/partner/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Distributor" >
                      <span>DISTRIBUTOR</span>
                      <Link to="/distributor/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Create -Sub Distributor" >
                      <span>SUB DISTRIBUTOR</span>
                      <Link to="/sub-distributor/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-Agent" >
                      <span>AGENT</span>
                      <Link to="/agent/form" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Create-User" >
                      <span>USER</span>
                      <Link to="/user/form" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>

                <Menu.SubMenu title="MEMBER LIST" key="menu" icon={<MenuFoldOutlined style={iconStyle} onTitleClick={() => handleSubMenuClick('menu')} />}>
                  {userRole === "superAdmin" && (
                    <Menu.Item key="Register" >
                      <span>ADMIN</span>
                      <Link to="/admin" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "superAdmin") && (
                    <Menu.Item key="Partner" >
                      <span>PARTNER</span>
                      <Link to="/partner" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Distributor" >
                      <span>DISTRIBUTOR</span>
                      <Link to="/distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "distributor" || userRole === "partner" || userRole === "superAdmin") && (
                    <Menu.Item key="Sub Distributor" >
                      <span>SUB DISTRIBUTOR</span>
                      <Link to="/sub-distributor" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor" || userRole === "superAdmin") && (
                    <Menu.Item key="Agent" >
                      <span>AGENT</span>
                      <Link to="/agent" />
                    </Menu.Item>
                  )}
                  {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor" || userRole === "superAdmin") && (
                    <Menu.Item key="User" >
                      <span>USER</span>
                      <Link to="/user" />
                    </Menu.Item>
                  )}
                </Menu.SubMenu>


                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINT MANAGEMENT" key="pointMenu" icon={<MenuFoldOutlined style={iconStyle} />}>
                    {(userRole === "admin") && (
                      <Menu.Item key="PartnerPoint" >
                        <span>PARTNER</span>
                        <Link to="/partner/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "partner") && (
                      <Menu.Item key="DistributorPoint" >
                        <span>DISTRIBUTOR</span>
                        <Link to="/distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "distributor" || userRole === "partner") && (
                      <Menu.Item key="Sub Distributor Point" >
                        <span>SUB DISTRIBUTOR</span>
                        <Link to="/sub-distributor/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "subDistributor" || userRole === "partner" || userRole === "distributor") && (
                      <Menu.Item key="AgentPoint" >
                        <span>AGENT</span>
                        <Link to="/agent/point" />
                      </Menu.Item>
                    )}
                    {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                      <Menu.Item key="UserPoint" >
                        <span>USER</span>
                        <Link to="/user/point" />
                      </Menu.Item>
                    )}
                  </Menu.SubMenu>
                )}

                {(userRole === "admin" || userRole === "agent" || userRole === "partner" || userRole === "distributor" || userRole === "subDistributor") && (
                  <Menu.SubMenu title="POINTS TRANSACTION" key="poinTransaction" icon={<MenuFoldOutlined style={iconStyle} />}>
                    <Menu.Item key="PartnerPointin">
                      <span>TRANSACTION IN</span>
                      <Link to="/transaction-in" />
                    </Menu.Item>
                    <Menu.Item key="PartnerPointout" >
                      <span>TRANSACTION OUT</span>
                      <Link to="/transaction-out" />
                    </Menu.Item>

                  </Menu.SubMenu>
                )}

                {userRole === "admin" && (
                  <>
                    <Menu.Item key="Game" icon={<HomeOutlined style={iconStyle} />}>
                      <span>GAME</span>
                      <Link to="/game" />
                    </Menu.Item>
                    <Menu.Item key="Commission" icon={<HomeOutlined style={iconStyle} />}>
                      <span>COMMISSION</span>
                      <Link to="/commission" />
                    </Menu.Item>
                  </>
                )}
              </Menu>
            </Sider>

            <Layout className="site-layout"
            >
              <Header style={{ position: "fixed", top: 0, zIndex: 1, width: "100%" }}>
                <div className="topbar">
                  <div className="topbarWrapper d-flex justify-content-end " >
                    <div className="topRight" style={{ paddingRight: isWindowShow ? "250px" : 0 }}>
                      {/* <div className="topbar-text">
                        <h6>{userData?.userName?.toUpperCase()}</h6>
                      </div>
                      <div className="topbarIcons">
                        <Popover content={content} trigger="hover">
                          <CaretDownOutlined />
                        </Popover>
                      </div> */}

                      <Popover content={content} trigger={['hover', 'click']} placement="bottom">
                        <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                          <div className="topbar-text">
                            <h6>{userData?.userName?.toUpperCase()}</h6>
                          </div>
                          <div className="topbarIcons" style={{ marginLeft: 8 }}>
                            <CaretDownOutlined />
                          </div>
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              </Header>

              <Content style={{ marginTop: 60 }}>
                <Outlet />
              </Content>
            </Layout>
          </Layout>

      }
    </>

  );
}

export default Sidebar;
