import React from "react";
import { Container } from "react-bootstrap";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Space, Table, Button, Image, Input } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import useToast from "../../provider/Toast/useToast";
import { apiAdminConfig } from "../../utils/api";
import { SearchOutlined } from '@ant-design/icons';


const Game = () => {
    const [gameList, setGameList] = React.useState([]);
    const [search, setSearch] = React.useState("");

    const toast = useToast();
    const navigate = useNavigate();

    const getgameList = async () => {
        await apiAdminConfig.get(`games/get?search=${search}`).then((response) => {
            if (response?.data?.results?.length > 0) {
                setGameList(response?.data?.results)
            }
        }).catch((error) => {
            console.log("gameListError", error);
        })
    }
    React.useEffect(() => {
        getgameList();
    }, [search])

    const onDelete = async (id) => {

    }

    const columns = [
        {
            title: 'Sr no',
            dataIndex: '_id',
            key: 'Srno',
            width: '10%',
            render: (_, record, index) => {
                return (
                    <>
                        {index + 1}
                    </>
                )
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '70%',
            key: 'Name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '70%',
            key: 'Description',
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            width: '70%',
            key: 'Duration',
        },
        {
            title: 'WinRatio',
            dataIndex: 'winRatio',
            width: '70%',
            key: 'WinRatio',
        },
        {
            title: 'Image',
            // dataIndex: 'image',
            width: '70%',
            render: (_, record) => (
                <Image
                    width={50} // Adjust width as needed
                    height={50}
                    src={`https://1b90-2402-a00-162-d066-708e-35a2-694e-7b56.ngrok-free.app${record.image}`}
                    preview={false} // Set to true if you want to enable the preview modal
                />
            )
        },

        {
            title: "Action",
            dataIndex: "action",
            width: '20%',
            key: 'Action',
            render: (_, record) => (
                <Space>
                    <Button
                        type="primary"
                        title="Edit"
                        style={{ padding: "2px 4px" }}
                        onClick={() => {
                            navigate(`/game/form`, { state: { data: record } })
                        }}
                    >
                        {" "}
                        <strong>
                            {" "}
                            <EditOutlined
                                style={{
                                    fontWeight: "900",
                                    fontSize: "16px",
                                    verticalAlign: "middle",
                                }}
                            />{" "}
                        </strong>{" "}
                    </Button>
                </Space>
            ),
        }
    ]




    return (
        <main id="gameLists">
            <Container className="admin_padding">
                <div className="admin_padding_table">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "14px 0",
                            alignItems: "center",
                            padding: "0 10px",
                        }}
                        className="table-hader-container"
                    >
                        <div>
                            <h3 className="table-hader-name">Game</h3>
                        </div>
                        <div className="search_container">
                            <Input
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="search_input"
                                style={{
                                    borderRadius: "5px",
                                    height: "40px",
                                }}
                            />
                            <Link to="/game/form" className="page_add_btn">
                                Add New
                            </Link>
                        </div>

                    </div>
                    <Table dataSource={gameList} columns={columns} scroll={{ x: 525 }} />
                </div>
            </Container>
        </main>
    );
};

export default Game;

