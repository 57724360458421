import React, { useEffect, useState } from 'react';
import "./style.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { Form, Row, Col, Button, InputGroup } from "react-bootstrap";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai"
import { apiAdminConfig } from '../../utils/api';
import useToast from '../../provider/Toast/useToast';
import { useNavigate } from 'react-router-dom';
import { setUserCookie } from '../../utils/cookie';
import Cookies from "js-cookie";



const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const[isRemeber,setIsRemeber]=useState(false)
  const [eyeIcon, setEyeIcon] = useState(<AiOutlineEyeInvisible />);
  const [captchaNumber, setCaptchaNumber] = useState(0);

  const validationSchema = yup.object().shape({
    userName: yup.string().required('User Name  is required'),
    password: yup.string()
      .min(4, 'Minimum 4 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    captcha: yup.string().required("Captcha is required"),
  });

  const { handleSubmit, handleChange, values, errors, touched ,setFieldValue} = useFormik({
    initialValues: {
      userName: "",
      password: "",
      captcha: "",
    },
    validationSchema,
    onSubmit: async () => {

      console.log(values.captcha, captchaNumber);

      if (values.captcha?.toString() !== captchaNumber?.toString()) {
        toast({
          message: "Captcha is not correct",
          bg: "danger",
        });
        return;
      }

      const { captcha, ...data } = values;

      await apiAdminConfig.post(`auth/login`, data).then((response) => {
        if(isRemeber){
          setCookie()
        }
        // toast({
        //   message: "Login Successfully",
        //   bg: 'success'
        // });
        const userData = {
          userData: { customerDetails: response?.data?.user },
          token: response?.data?.token?.access.token
        };
        setUserCookie(userData);
        navigate('/');

      }).catch((error) => {
        const { response } = error;
        toast({
          message: response?.data?.message,
          bg: "danger",
        });
      })
    },
  });

  const passwordView = () => {
    if (type === "password") {
      setType("text");
      setEyeIcon(<AiOutlineEye />);
    } else {
      setType("password");
      setEyeIcon(<AiOutlineEyeInvisible />);
    }
  }

  useEffect(() => {
    const storedUsername = Cookies.get("username");
    const storedPassword = Cookies.get("password");

    if (storedUsername && storedPassword) {
      setFieldValue('userName', storedUsername)
      setFieldValue('password', storedPassword)
    }
  }, [])

  const setCookie = () => {
    const username = values.userName
    const password = values.password

    Cookies.set("username", username, { expires: 7 });
    Cookies.set("password", password, { expires: 7 });
  }

  useEffect(() => {
    setCaptchaNumber(Math.floor(1000 + Math.random() * 9000));
  }, []);


  return (
    <>
      <div className='login_page' style={{
        // backgroundImage: 'url(/asset/loginBackGround.jpeg)',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8)), url(/asset/loginBackGround.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        // height: '100vh',
        // color: '#fff',
        // textAlign: 'center',
        // padding: '20px'
      }}>
        <div className='login_page_form'  >
          {/* <img alt="logo" src="/asset/logo.png" /> */}
          <div className="text-center project-name">
              <h6 className="text-black">GOALOCAL</h6>
            </div>
          <h2>Sign In</h2>
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group >
                <Form.Label>
                  User Name<span className="text-danger">*</span>
                </Form.Label>

                <Form.Control
                  type="userName"
                  placeholder=""
                  name="userName"
                  value={values.userName}
                  onChange={handleChange}
                  isInvalid={!!errors.userName && touched.userName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  Password<span className="text-danger">*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type={type}
                    placeholder=""
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password && touched.password}
                  />
                  <InputGroup.Text
                    onClick={passwordView}
                    className="icon-btn"
                  >
                    {eyeIcon}
                  </InputGroup.Text>

                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>

              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>
                  Captcha<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="captcha"
                  value={values.captcha}
                  onChange={handleChange}
                  isInvalid={!!errors.captcha && touched.captcha}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.captcha}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>


            <div className="text-center catpcha">
              <h6 className="text-white">{captchaNumber}</h6>
            </div>

            <div className='d-flex'>
              <input type='checkbox' value={isRemeber} onChange={(e)=>{setIsRemeber(e.target.value)}} style={{marginRight:"5px"}}/>
              <h6 style={{margin:0}}>Remember me</h6>
            </div>

            {/* <div className="d-grid mt-2">
              <Button
                type="submit"
                className='form_btn'
              >
                Login
              </Button>
            </div> */}

            <div className="text-center login-btn">
              <Button
                type="submit"
                className='form_btn'
              >
                Sign In
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Login;
