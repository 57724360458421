import "../style.scss";
import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { apiAdminConfig } from "../../../utils/api";
import moment from "moment";
import { convertArrayToString } from "../../../utils/helper";
import { Form } from "react-bootstrap";

const AgentViewModel = () => {
    const location = useLocation();
    const [agentDetail, setAgentDetail] = useState(null)
    const [lockStatus, setLockStatus] = useState(false)

    const getAgentDetail = async () => {
        await apiAdminConfig.get(`users/get/${location?.state?.data?.id}`).then((response) => {
            if (response?.status == 200) {
                setAgentDetail(response?.data)
                setLockStatus(response?.data?.lockStatus == 'locked' ? true : false)
            }
        }).catch((error) => {
            console.log("error", error);
        })
    }

    useEffect(() => {
        getAgentDetail()
    }, [location]);


    const handleLockStatus = (e) => {
        let status = e.target.checked ? 'locked' : 'unlocked';
        setLockStatus(e.target.checked)
        apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, { lockStatus: status }).then((response) => {
            if (response?.status == 200) {
                getAgentDetail()
            }
        }).catch((error) => {
            console.log("error", error);
        })
    }




    return <>
        <main id="agentView">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "14px 0",
                    alignItems: "center",
                }}
                className="table-hader-container"
            >
                <Link to="/agent" className="page_add_btn" style={{ marginLeft: "20px" }}>
                    Back
                </Link>
            </div>

            <div className='d-flex mt-3' >
                <div className='card text-black  mb-3'>
                    <div className="card-body">
                        <h5 className="card-title">Agent Detail</h5>
                        <hr></hr>
                        <p>
                            <span className="label">User Name</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.userName || '-'}</span>
                        </p>
                        <p>
                            <span className="label">Point</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.points || '0'}</span>
                        </p>
                        <p>
                            <span className="label">Email</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.email || '-'}</span>
                        </p>
                        <p>
                            <span className="label">Phone No</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.phone || '-'}</span>
                        </p>
                        {
                            agentDetail?.admin &&
                            <p>
                                <span className="label">Admin</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.admin?.userName || '-'}</span>
                            </p>
                        }
                        {
                            agentDetail?.partner &&
                            <p>
                                <span className="label">Partner</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.partner?.userName || '-'}</span>
                            </p>
                        }
                        {
                            agentDetail?.distributor &&
                            <p>
                                <span className="label">Distributor</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.distributor?.userName || '-'}</span>
                            </p>
                        }
                        {
                            agentDetail?.subDistributor &&
                            <p>
                                <span className="label">Sub Distributor</span>
                                <span className="colon">:</span>
                                <span className="value">{agentDetail?.subDistributor?.userName || '-'}</span>
                            </p>
                        }
                        <p>
                            <span className="label">Last Login</span>
                            <span className="colon">:</span>
                            <span className="value">{moment(agentDetail?.lastLogin).format('DD-MM-YYYY') || '-'}</span>
                        </p>
                        <p>
                            <span className="label">Locked Status</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.lockStatus || '-'}</span>
                        </p>
                        <p>
                            <span className="label">Locked By</span>
                            <span className="colon">:</span>
                            <span className="value">{agentDetail?.lockedBy?.userName || 'NULL'}</span>
                        </p>
                        <p>
                            <span className="label">Eligible Game</span>
                            <span className="colon">:</span>
                            <span className="value">{
                                agentDetail?.games?.length > 0 ? convertArrayToString(agentDetail?.games?.map((item) => item.name)) : '-'
                            }</span>
                        </p>
                        <p>
                            <span className="label">Lock</span>
                            <span className="colon">:</span>
                            <label className="switch">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    className="custom-switch"
                                    checked={lockStatus}
                                    onChange={(e) => handleLockStatus(e)}
                                />
                            </label>
                        </p>
                    </div>
                </div>
            </div>
        </main >
    </>
}

export default AgentViewModel

















