import "../style.scss";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { apiAdminConfig } from "../../../utils/api";
import moment from "moment";
import { convertArrayToString } from "../../../utils/helper";
import { Form } from "react-bootstrap";


const DestributorViewModel = () => {
  const location = useLocation();
  const [destributorDetail, setDestributorDetail] = useState(null)
  const [lockStatus, setLockStatus] = useState(false)

  const getDistributorDetail = async () => {
    await apiAdminConfig.get(`users/get/${location?.state?.data?.id}`).then((response) => {
      if (response?.status == 200) {
        setDestributorDetail(response?.data)
        setLockStatus(response?.data?.lockStatus == 'locked' ? true : false)
      }
    }).catch((error) => {
      console.log("error", error);
    })
  }

  useEffect(() => {
    getDistributorDetail()
  }, [location]);

  const handleLockStatus = (e) => {
    let status = e.target.checked ? 'locked' : 'unlocked';
    setLockStatus(e.target.checked)
    apiAdminConfig.put(`users/update/${location?.state?.data?.id}`, { lockStatus: status }).then((response) => {
      if (response?.status == 200) {
        getDistributorDetail()
      }
    }).catch((error) => {
      console.log("error", error);
    })
  }

  return <>
    <main id="destributorView">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "14px 0",
          alignItems: "center",
        }}
        className="table-hader-container"
      >
        <Link to="/distributor" className="page_add_btn" style={{ marginLeft: "20px" }}>
          Back
        </Link>
      </div>
      <div className='d-flex mt-3' >
        <div className='card text-black  mb-3'>
          <div className="card-body">
            <h5 className="card-title">Destributor Detail</h5>
            <hr></hr>
            <p>
              <span className="label">User Name</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.userName || '-'}</span>
            </p>
            <p>
              <span className="label">Point</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.points || '0'}</span>
            </p>
            <p>
              <span className="label">Email</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.email || '-'}</span>
            </p>
            <p>
              <span className="label">Phone No</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.phone || '-'}</span>
            </p>
            {
              destributorDetail?.admin &&
              <p>
                <span className="label">Admin</span>
                <span className="colon">:</span>
                <span className="value">{destributorDetail?.admin?.userName || '-'}</span>
              </p>
            }
            {
              destributorDetail?.partner &&
              <p>
                <span className="label">Partner</span>
                <span className="colon">:</span>
                <span className="value">{destributorDetail?.partner?.role || '-'}</span>
              </p>
            }
            <p>
              <span className="label">Last Login</span>
              <span className="colon">:</span>
              <span className="value">{moment(destributorDetail?.lastLogin).format('DD-MM-YYYY') || '-'}</span>
            </p>
            <p>
              <span className="label">Locked Status</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.lockStatus || '-'}</span>
            </p>
            <p>
              <span className="label">Locked By</span>
              <span className="colon">:</span>
              <span className="value">{destributorDetail?.lockedBy?.userName || 'NULL'}</span>
            </p>
            <p>
              <span className="label">Eligible Game</span>
              <span className="colon">:</span>
              <span className="value">{
                destributorDetail?.games?.length > 0 ? convertArrayToString(destributorDetail?.games?.map((item) => item.name)) : '-'
              }</span>

            </p>
            <p>
              <span className="label">Lock</span>
              <span className="colon">:</span>
              <label className="switch">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  className="custom-switch"
                  checked={lockStatus}
                  onChange={(e) => handleLockStatus(e)}
                />
              </label>
            </p>
          </div>
        </div>
      </div>
    </main >
  </>
}

export default DestributorViewModel

















